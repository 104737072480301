import React, { useEffect, useMemo, useState } from 'react'
import { NoDataFound, SearchInput } from '../common';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import Table from '../common/Table/Table';
import { deletePatient } from '../../utils/patientsUtils/patientsUtils';

const PatientsTable = ({
  patients,
  setDatasFn,
}) => {
  const headings = useMemo(
    () => [
      "Name",
      "P-ID",
      "Phone",
      "Email",
      "Platform",
      "Appointments",
      "Delete",
    ],
    []
  );
  const delData = (paitnetId) => {
    deletePatient(paitnetId)
      .then((res) => setDatasFn())
      .catch((err) => console.log(err));
  };
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = patients?.filter((data) => {
      const nameMatch = data?.name
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const pIdMatch = data?.patient_id
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const numberMatch = data?.phone_number
        .toString()
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const emailMatch = data?.email
        .toString()
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      return nameMatch || numberMatch || emailMatch || pIdMatch;
    });
    setFilteredData(filteredData);
  }, [patients, searchQuery]);

  const redirectToAppointments = (data, selected) =>{
  const searchParams = new URLSearchParams({
        selected: selected,
        patient_id: data?.patient_id,
        email: data?.email,
        phone_number: data?.phone_number,
      }).toString();
      window.location.href = `/?${searchParams}`;
  }
  return (
    <div className="h-full  w-full mt-3">
      <div className="flex w-full justify-center items-center mb-5">
        <div className="flex w-full sm:w-1/2">
          <SearchInput
            placeholder={"Search"}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
      </div>
      {filteredData?.length > 0 ? (
        <Table headings={headings}>
          {filteredData?.map((data, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">{data?.name}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">{data?.patient_id}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {data?.phone_number}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">{data?.email}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {data?.social_media}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap flex items-start gap-2 ">
                <button
                  className="flex justify-center items-center gap-2 text-xs border rounded-md bg-green-600 text-white p-2"
                  onClick={() => {
                    redirectToAppointments(data, "1");
                  }}
                >
                  Consultancy
                </button>
                <button
                  className="flex justify-center items-center gap-2 text-xs border rounded-md bg-blue-600 text-white p-2"
                  onClick={() => {
                    redirectToAppointments(data, "2");
                  }}
                >
                  Lab
                </button>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div
                  className="text-sm text-gray-900 cursor-pointer"
                  onClick={() => {
                    delData(data?._id);
                  }}
                >
                  <MdOutlineDeleteOutline color="red" />
                </div>
              </td>
            </tr>
          ))}
        </Table>
      ) : (
        <NoDataFound data={"paitents"} />
      )}
    </div>
  );
};

export default PatientsTable