import React, { useCallback, useEffect, useState } from "react";
import PatientsTable from "./PatientsTable";
import { getPatients } from "../../utils/patientsUtils/patientsUtils";

const PatientsMainSection = () => {
  const [patients, setPatients] = useState([]);
  const setPatientsFn = useCallback(() => {
    getPatients()
      .then((result) => {
        setPatients(result.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  useEffect(() => setPatientsFn(), [setPatientsFn]);
  return (
    <>
      <div className="flex flex-col w-full h-full">
        <div className="flex w-full justify-between items-center gap-3"></div>
        <PatientsTable
          patients={patients}
          setDatasFn={setPatientsFn}
        />
      </div>
    </>
  );
};

export default PatientsMainSection;
