import React from "react";
import { GrFormClose } from "react-icons/gr";

const AppointmentModebuttons = ({
  appointmentMode,
  setAppointmentMode,
  setOpen,
}) => {
  return (
    <div className="flex flex-col h-full p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-center text-lg font-bold">
          Select Appointment Mode
        </h2>
        <span
          className="cursor-pointer"
          onClick={() => {
            setOpen(false);
          }}
        >
          <GrFormClose fontSize={19} />
        </span>
      </div>
      <div className="flex flex-col md:flex-row w-full h-full justify-center items-center gap-5">
        <button
          className={`w-full md:w-1/2 border border-primary py-2 rounded-md ${
            appointmentMode === "consultancy"
              ? "bg-primary text-white"
              : "bg-white text-primary"
          } text-shadow-black flex justify-center items-center transition duration-300 ease-in-out hover:bg-primary hover:text-white`}
          onClick={() => {
            setAppointmentMode("consultancy");
          }}
        >
          Consultancy
        </button>
        <button
          className={`w-full md:w-1/2 border border-primary py-2 rounded-md ${
            appointmentMode === "lab"
              ? "bg-primary text-white"
              : "bg-white text-primary"
          } text-shadow-black flex justify-center items-center transition duration-300 ease-in-out hover:bg-primary hover:text-white`}
          onClick={() => {
            setAppointmentMode("lab");
          }}
        >
          Lab
        </button>
      </div>
    </div>
  );
};

export default AppointmentModebuttons;
