import React from 'react'
import "./DropDown.css"
import { NavLink } from 'react-router-dom';

const DropDown = ({drop,links}) => {
  return (
    <div
      className={`dropdown-container ${
        drop ? "h-fit" : "h-0"
      }`}
    >
      {links.map(({ to, destination }, index) => (
        <NavLink to={to} key={index} className="dropdown-link ">
          <div>{destination}</div>
          <div className="active-line"></div>
        </NavLink>
      ))}
    </div>
  );
}

export default DropDown