import { Route, Routes } from "react-router-dom";
import Layout from "./layout/Layout";
import { Appointments, OnlineAppointments, Patients } from "./pages";


function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index={true} element={<Appointments/>} />
        <Route path="/patients" element={<Patients />} />
        <Route path="/online/appointments" element={<OnlineAppointments/>} />
      </Route>
    </Routes>
  );
}

export default App;

