import React, { useState } from "react";
import AppointmentModebuttons from "./AppointmentModebuttons";
import ConsultationAppointmentForm from "./ConsultationAppointmentForm";
import LabApppointmentForm from "./LabAppointmentForm";

const AppointmentModal = ({
  appointmentDetails,
  is_follow_up,
  is_re_visit,
  mode,
  setOpen,
  setDatasFn = null,
}) => {
  const [appointmentMode, setAppointmentMode] = useState(
    is_follow_up && is_re_visit ? mode : ""
  );
  return (
    <div className="absolute flex w-full h-full  top-0 left-0 bottom-0 justify-center items-center p-4  ">
      <div
        className={`flex bg-white w-full max-w-screen-lg overflow-y-auto h-full border shadow-lg  rounded-md gap-5 px-4 pb-4 pt-2 flex-col`}
      >
        {!appointmentMode && (
          <AppointmentModebuttons
            setOpen={setOpen}
            appointmentMode={appointmentMode}
            setAppointmentMode={setAppointmentMode}
          />
        )}
        {appointmentMode && appointmentMode === "consultancy" && (
          <ConsultationAppointmentForm
            setOpen={setOpen}
            setDatasFn={setDatasFn}
            appointmentDetails={appointmentDetails}
            is_follow_up={is_follow_up}
            is_re_visit={is_re_visit}
          />
        )}
        {appointmentMode && appointmentMode === "lab" && (
          <LabApppointmentForm
            setOpen={setOpen}
            setDatasFn={setDatasFn}
            appointmentDetails={appointmentDetails}
            is_follow_up={is_follow_up}
          />
        )}
      </div>
    </div>
  );
};

export default AppointmentModal;
