import { axiosInstance } from "../../api/config";

export const getConsultatoinAppointments = async () => {
    try {
        const response = await axiosInstance.get(`/reception/get_consultant`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getOnlineConsultatoinAppointments = async () => {
    try {
        const response = await axiosInstance.get(`/appointment/get_appointment`);
        return response;
    } catch (error) {
        throw error;
    }
}


export const addConsultationAppointment = async (initialState, doctor, time, slot, date, is_follow_up) => {
  try {
    const requestData = {
      ...initialState,
      doctor_id: doctor?._id,
      total_price: doctor?.price,
      date: date.date,
      dateObject: date,
      slot,
      time,
      is_follow_up,
    };
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };
    const url =  `/reception/create_consultant`;
    const response = await axiosInstance['post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const verfiyConsultationBookingOtp = async (initialState, otp, setErr) => {
    try {
        const requestData = {
            visit_id: initialState.visit_id,
            otpVerify: otp
        }
        const requestConfig = {
            headers: { "Content-Type": "application/json" },
        };
        const response = await axiosInstance.post(`/consultant/verify`, requestData, requestConfig);
        return response;
    } catch (error) {
        setErr({ global: "Otp Not Matching" })
        throw error;
    }
}

export const verfiyFollowupConsultationBookingOtp = async (initialState, otp, setErr) => {
    try {
        const requestData = {
            visit_id: initialState.visit_id,
            otpVerify: otp
        }
        const requestConfig = {
            headers: { "Content-Type": "application/json" },
        };
        const response = await axiosInstance.post(`/consultant_followup/verify`, requestData, requestConfig);
        return response;
    } catch (error) {
        setErr({ global: "Otp Not Matching" })
        throw error;
    }
}


export const deleteApponintment = async(appointmentId) => {
    try {
        const response = await axiosInstance.delete(`/reception/delete_consultant/${appointmentId}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getConsultationFollowupAppointments = async () => {
    try {
        const response = await axiosInstance.get(`/receptionconsultantfollowup`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const addConsultationFollowupAppointments = async (initialState, doctor, time, slot, date, patient_id) => {
    try {
        const requestData = {
            name: initialState.name,
            phone_number: initialState.contact,
            email: initialState.email || '',
            doctor_id: doctor?._id,
            date: date.date,
            gender: initialState.gender,
            dateObject: date,
            slot,
            time,
            payment_status: initialState.status,
            patient_id
        };
        const requestConfig = {
            headers: { "Content-Type": "application/json" },
        };
        const url = `/reception/create_consultantfollowup`;
        const response = await axiosInstance['post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteConsultationFollowupApponintment = async (appointmentId) => {
    try {
        const response = await axiosInstance.delete(`/reception/delete_consultantfollowup/${appointmentId}`);
        return response;
    } catch (error) {
        throw error;
    }
}