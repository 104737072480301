import { axiosInstance } from "../../api/config";

export const getTests = async () => {
  try {
    const response = await axiosInstance.get("/labtest/get_labtest");
    return response;
  } catch (error) {
    throw error;
  }
}

export const getLabAppointments = async () => {
  try {
    const response = await axiosInstance.get(`/reception/get_labbooking`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getLabFollowupAppointments = async () => {
  try {
    const response = await axiosInstance.get(`/receptionlabfollowup`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const addLabFollowupAppointments = async (initialState, test, patient_id) => {
  try {
    const requestData = {
      name: initialState.name,
      phone_number: initialState.contact,
      email: initialState.email || '',
      patient_id,
      doctor_name: initialState.doctor_name,
      lab_test: test?._id,
      payment_status: initialState.status,
    };
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };
    const url = `/reception/create_labfollowup`;
    const response = await axiosInstance['post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}



export const addLabAppointment = async (initialState, tests, total_price, is_follow_up) => {
  try {
    const requestData = {
      ...initialState,
      total_price,
      tests,
      is_follow_up
    };
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };
    const url = `/reception/create_labbooking`;

    const response = await axiosInstance['post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const verfiyLabBookingOtp = async (initialState, otp, setErr) => {
  try {
    const requestData = {
      visit_id: initialState.visit_id,
      otpVerify: otp
    }
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };
    const response = await axiosInstance.post(`/labbooking/otpverify`, requestData, requestConfig);
    return response;
  } catch (error) {
    setErr({ global: "Otp Not Matching" })
    throw error;
  }
}

export const verfiyLabFollowupBookingOtp = async (initialState, otp, setErr) => {
  try {
    const requestData = {
      visit_id: initialState.visit_id,
      otpVerify: otp
    }
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };
    const response = await axiosInstance.post(`/lab_followup/verify`, requestData, requestConfig);
    return response;
  } catch (error) {
    setErr({ global: "Otp Not Matching" })
    throw error;
  }
}


export const deleteLabApponintment = async (appointmentId) => {
  try {
    const response = await axiosInstance.delete(`/reception/delete_labbooking/${appointmentId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteLabFollowupApponintment = async (appointmentId) => {
  try {
    const response = await axiosInstance.delete(`/reception/delete_labfollowup/${appointmentId}`);
    return response;
  } catch (error) {
    throw error;
  }
}