import React, { useEffect, useMemo, useState } from 'react'
import Table from '../../common/Table/Table';
import SearchInput from '../../common/SearchInput/SearchInput';
import { NoDataFound } from '../../common';

const OnlineConsultation = ({ appointments }) => {
    const headings = useMemo(
      () => [
        "Name",
        "P-ID",
        "V-ID",
        "Contact",
        "Date",
        "Time",
        "Doctor",
        "Treatment",
      ],
      []
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = appointments?.filter((data) => {
      const nameMatch = data?.name?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const pIdMatch = data?.patient_id?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const vIdMatch = data?.visit_id?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const numberMatch = data?.phone_number?.toString()?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const doctorMatch = data?.doctor_id?.name?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      return nameMatch || pIdMatch || vIdMatch || numberMatch  || doctorMatch ;
    });
    setFilteredData(filteredData);
  }, [appointments, searchQuery]);
  return (
    <div className="h-full  w-full mt-3">
      <div className="flex w-full justify-center items-center mb-5">
        <div className="flex w-full sm:w-1/2">
          <SearchInput
            placeholder={"Search"}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
      </div>
      {filteredData?.length > 0 ? (
        <Table headings={headings}>
          {filteredData?.map((data, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">{data?.name}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">{data?.patient_id}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 capitalize">
                  {data?.visit_id}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 capitalize">
                  {data?.phone_number}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 capitalize">
                  {data?.date}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 capitalize">
                  {data?.time}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 capitalize">
                  {data?.doctor_id?.name}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 capitalize">
                  {data?.disease}
                </div>
              </td>
            </tr>
          ))}
        </Table>
      ) : (
        <NoDataFound data={"appointments"} />
      )}
    </div>
  );
}

export default OnlineConsultation