import { axiosAuthorized } from "../../api/config"

export const getDepartments = async () => {
    try {
        const response = await axiosAuthorized.get("/department/get_department");
        return response;
    } catch (error) {
        throw error;
    }
}

export const addDepartment = async (department, department_description, department_image, meta_name,meta_description,meta_tag, departmentId = null) => {
    try {
        const requestData = {
            department,
            department_image,
            department_description,
            meta_name,
            meta_description,
            meta_tag
        };
        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };

        const url = departmentId
            ? `/department/update_department/${departmentId}`
            : `/department/add_department`;

        const response = await axiosAuthorized[departmentId ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteDepartment = async (departmentId) => {
    try {
        const response = await axiosAuthorized.delete(`/department/delete_department/${departmentId}`);
        return response;
    } catch (error) {
        throw error;
    }
}