import { axiosInstance } from "../../api/config";

export const addRegister = async (initialState, appointmentId = null) => {
  try {
    const requestData = {
      ...initialState
    };
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };
    const url = appointmentId
      ? `/patientregister/update_patientregister/${appointmentId}`
      : `/patientregister/add_patientregister`;
    const response = await axiosInstance[appointmentId ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}
export const verifyRegisterWithOtp = async (email, otpVerify, setErr) => {
  try {
    const requestData = {
      email,
      otpVerify
    };
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };
    const url = `/patientregisterotpveriy`
    const response = await axiosInstance['post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    setErr({ global: "Otp Not Matching" })
    throw error;
  }
}