import React, {  useCallback, useEffect, useState } from 'react'
import { getOnlineConsultatoinAppointments } from '../../utils/bookConsultation/ConsultationUtils';
import OnlineConsultation from './OnlineConsultaion/OnlineConsultation';

const OnlineAppointMainSection = () => {
  const [consultationAppointments, setConsultaionAppointments] = useState([]);
  const setconsultationAppointmentsFn = useCallback(() => {
    getOnlineConsultatoinAppointments()
      .then(res => { setConsultaionAppointments(res.data.data) })
      .catch(err => console.log(err));
  },[])
  useEffect(() => {
    setconsultationAppointmentsFn(); 
  }, [setconsultationAppointmentsFn]);
        useEffect(() => {
          const intervalId = setInterval(() => {
            getOnlineConsultatoinAppointments();
          }, 5 * 60 * 1000);
          return () => clearInterval(intervalId);
        }, [getOnlineConsultatoinAppointments]);
  return (
    <>
    <div className="flex flex-col w-full h-full">
      <div className="flex w-full justify-between items-center gap-3">
        <div className="flex gap-3">
          <button
            className={`flex px-3 border border-primary py-1 rounded-md ${
              "bg-primary text-white"
            }`}
          >
            Consultation
          </button>
        </div>
      </div>
      {<OnlineConsultation appointments={consultationAppointments}  />}
      </div>
    </>
  );
}

export default OnlineAppointMainSection