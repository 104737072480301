import React, { useState } from "react";
import RegisterForm from "./RegisterForm";
import RegisterOtpField from "./RegisterOtpField";
import AppointmentModal from "./AppointmentModal";

const RegisterModal = ({setOpen ,setDatasFn = null}) => {
  const [response, setResponse] = useState(null);
  const [otpSended, setOtpSended] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const closeAll = (value) => {
    setOpen(value);
    setOtpSended(value);
    setResponse(null);
    setOtpVerified(value);
  }
  return (
    <div className="absolute flex w-full h-full  top-0 left-0 bottom-0 justify-center items-center p-4  ">
      <div
        className={`flex bg-white w-full max-w-screen-lg overflow-y-auto h-fit border shadow-lg  rounded-md gap-5 px-4 pb-4 pt-2 flex-col`}
      >
        {!otpSended && <RegisterForm setOtpSended={setOtpSended} setResponse={setResponse} setOpen={setOpen} />}
        { response && otpSended && <RegisterOtpField response={response} setOtpSended={setOtpSended} setOtpVerified={setOtpVerified} />}
        { response && otpVerified && <AppointmentModal appointmentDetails={response} setDatasFn={setDatasFn}  mode={""} setOpen={closeAll} is_follow_up={false} is_re_visit={false}/>}
      </div>
    </div>
  );
};

export default RegisterModal;
