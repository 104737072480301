import React from "react";
import PatientsMainSection from "../../components/PatientsComponents/PatientsMainSection";

const Patients = () => {
  return (
    <div className="w-full h-full px-2 pt-10 sm:p-10 flex flex-col gap-4 relative">
      <PatientsMainSection />
    </div>
  );
};

export default Patients;
