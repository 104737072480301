import React from 'react'
import "./Layout.css"
import { Outlet } from "react-router-dom";
import NavBar from '../components/NavBar/NavBar';

const Layout = () => {
  return (
    <div className="layout">
      <div className="navbar-parent">
        <NavBar/>
      </div>
      <div className="outlet-parent">
        <Outlet />
      </div>
    </div>
  );
}

export default Layout