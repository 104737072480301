export const validataLabForm = (initialState, tests, setError) => {
  if (initialState.date) {
    setError({ global: "Please Select Date" });
    return false;
  }
  if (initialState.time) {
    setError({ global: "Please Select Time" });
    return false;
  }
  if (initialState.payment_method === "Select Method") {
    setError({ global: "Please Select Payment Method" });
    return false;
  }
  if (initialState.payment_status === "Select Status") {
    setError({ global: "Please Select Payment Status" });
    return false;
  }
  if (!tests) {
    setError({ global: "Please Select At Least One Test" });
    return false;
  }
  return true;
}

export const validataLabFollowupForm = (initialState, lab, setError) => {
    const namePattern = /^[A-Za-z\s.]+$/;
    const emailPattern = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;
    const phoneNumberPattern = /^(?!0{10}$)\d{10}$/;

    if (!namePattern.test(initialState.name)) {
        setError({ global: "Invalid Name" });
        return false;
    }

    if (!phoneNumberPattern.test(initialState.contact)) {
        setError({ global: "Invalid Phone Number" });
        return false;
    }

    if (initialState.email.length && (!emailPattern.test(initialState.email))) {
        setError({ global: "Invalid Email" });
        return false;
    }

    if (!namePattern.test(initialState.test)) {
        setError({ global: "Invalid Test" });
        return false;
    }

    if (initialState.status === "Select Status") {
        setError({ global: "Please Select Payment Status" });
        return false;
    }

    if (!lab) {
        setError({ global: "Please Select Lab" });
        return false;
    }

    return true;
}
