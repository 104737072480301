import React, { useEffect, useMemo, useState } from "react";
import Table from "../../common/Table/Table";
import SearchInput from "../../common/SearchInput/SearchInput";
import { NoDataFound } from "../../common";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { deleteApponintment } from "../../../utils/bookConsultation/ConsultationUtils";

const Consultation = ({
  appointments,
  setAppointmentsFn,
  setIsRevisit,
  setIsFollowup,
  setPatientDetails,
  setMode,
  searchParams,
}) => {
  const { patient_id, email, phone_number } = searchParams;
  const headings = useMemo(
    () => [
      "Type",
      "Name",
      "P-ID",
      "V-ID",
      "Phone",
      "Email",
      "Date",
      "Time",
      "Disease",
      "Doctor",
      "Status",
      "Platform",
      "Revisit",
      "New Follow-up",
      "Delete",
    ],
    []
  );
  const delAppointment = (appointmentId) => {
    deleteApponintment(appointmentId)
      .then((res) => setAppointmentsFn())
      .catch((err) => console.log(err));
  };
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = appointments?.filter((data) => {
      const nameMatch = data?.patient_id.name
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const pIdMatch = data?.patient_id?.patient_id
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const vIdMatch = data?.visit_id
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const numberMatch = data?.patient_id?.phone_number
        .toString()
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const emailMatch = data?.patient_id?.email
        .toString()
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const diseaseMatch = data?.disease
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const departmentMatch = data?.doctor_id?.department_id?.departmet
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const doctorMatch = data?.doctor_id?.name
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const patientIdFilter = patient_id
        ? data?.patient_id?.patient_id?.toString()?.toLowerCase() ===
          patient_id?.toString()?.toLowerCase()
        : true;
      const emailFilter = email
        ? data?.patient_id?.email?.toString()?.toLowerCase() ===
          email?.toString()?.toLowerCase()
        : true;
      const phoneNumberFilter = phone_number
        ? data?.patient_id?.phone_number?.toString()?.toLowerCase() ===
          phone_number?.toString()?.toLowerCase()
        : true;
      return (
        (nameMatch ||
          numberMatch ||
          emailMatch ||
          pIdMatch ||
          diseaseMatch ||
          departmentMatch ||
          doctorMatch ||
          vIdMatch) &&
        patientIdFilter &&
        emailFilter &&
        phoneNumberFilter
      );
    });
    setFilteredData(filteredData);
  }, [appointments, email, patient_id, phone_number, searchQuery]);
  const handleRevisit = (data) => {
    setMode("consultancy");
    setIsRevisit(true);
    setIsFollowup(true);
    setPatientDetails(data);
  };
  const handleFollowUp = (data) => {
    setMode("consultancy");
    setIsFollowup(true);
    setPatientDetails(data);
  };
      useEffect(() => {
        const intervalId = setInterval(() => {
          setAppointmentsFn();
        }, 5 * 60 * 1000);
        return () => clearInterval(intervalId);
      }, [setAppointmentsFn]);
  return (
    <div className="h-full  w-full mt-3">
      <div className="flex w-full justify-center items-center mb-5">
        <div className="flex w-full sm:w-1/2">
          <SearchInput
            placeholder={"Search"}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
      </div>
      {filteredData?.length > 0 ? (
        <Table headings={headings}>
          {filteredData?.map((data, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {data?.is_follow_up ? "Follow-up" : "Initial"}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {data?.patient_id?.name}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {data?.patient_id?.patient_id}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 ">{data?.visit_id}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 ">
                  {data?.patient_id?.phone_number}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {data?.patient_id?.email}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 ">{data?.date}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 ">{data?.time}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 ">{data?.disease}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 ">
                  {data?.doctor_id?.name}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 ">
                  {data?.payment_status}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 ">
                  {data?.patient_id?.social_media}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <button
                  className="flex justify-center items-center gap-2 text-xs border rounded-md bg-green-600 text-white p-2"
                  onClick={() => {
                    handleRevisit(data);
                  }}
                >
                  Revisit
                </button>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <button
                  className="flex justify-center items-center gap-2 text-xs border rounded-md bg-blue-600 text-white p-2"
                  onClick={() => {
                    handleFollowUp(data);
                  }}
                >
                  New Follow-up
                </button>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div
                  className="text-sm text-gray-900 capitalize cursor-pointer"
                  onClick={() => {
                    delAppointment(data?._id);
                  }}
                >
                  <MdOutlineDeleteOutline color="red" />
                </div>
              </td>
            </tr>
          ))}
        </Table>
      ) : (
        <NoDataFound data={"appointments"} />
      )}
    </div>
  );
};

export default Consultation;
