import { axiosInstance } from "../../api/config";

export const getPatients = async () => {
  try {
    const response = await axiosInstance.get(`/verifiedregisterpatient`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deletePatient = async (patientId) => {
  try {
    const response = await axiosInstance.delete(`/patientregister/delete_patientregister/${patientId}`);
    return response;
  } catch (error) {
    throw error;
  }
}