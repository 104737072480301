import React, { useEffect, useMemo, useState } from "react";
import "./NavBar.css";
import { BlackBarSvg } from "../../svg";
import { BiMenuAltRight } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import DropDown from "./DropDown/DropDown";


const NavBar = () => {
  const links = useMemo(
    () => [
      { to: "/", destination: "Appointments" },
      { to: "/patients", destination: "Patients" },
      { to: "/online/appointments", destination: "Online Appointments" },
    ],
    []
  );
  const [drop, setDrop] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setDrop(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div className="navbar">
        <div className="logo-container">
          <div className="logo">
            <img src="/assets/logo/life_plus_logo.png" alt="life_plus_logo" />
          </div>
          <BlackBarSvg />
        </div>
        <div className="navlinks ">
          {links.map(({ to, destination }, index) => (
            <NavLink to={to} key={index} className="navlink-class">
              <div>{destination}</div>
              <div className="active-dot"></div>
            </NavLink>
          ))}
        </div>
        <div>
          <BiMenuAltRight
            fontSize={25}
            className="mb-2 mr-3 flex lg:hidden cursor-pointer"
            onClick={() => {
              setDrop(!drop);
            }}
          />
        </div>
      </div>
      {drop && <DropDown links={links} drop={drop} />}
    </>
  );
};

export default NavBar;
