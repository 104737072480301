import React from "react";
import "./SearchInput.css";
const SearchInput = ({placeholder,searchQuery,setSearchQuery}) => {
    return (
      <>
        <input
          type="text"
          placeholder={placeholder}
          className="search-input"
          value={searchQuery}
          onChange={(e)=>{setSearchQuery(e.target.value)}}
        />
      </>
    );
};

export default SearchInput;
