import { axiosInstance } from "../../api/config";

export const getDoctors = async () => {
    try {
        const response = await axiosInstance.get("/doctor/get_doctor");
        return response;
    } catch (error) {
        throw error;
    }
}
