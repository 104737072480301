import React, { useEffect, useState } from "react";
import { DateInput, SelectInput, TimeInput } from "../common/FormComponents";
import { useForm } from "../../hooks";
import { NoDataFound, SearchInput } from "../common";
import { addLabAppointment, getTests } from "../../utils/bookLab/labUtils";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaRupeeSign } from "react-icons/fa";
import { validataLabForm } from "../../utils/bookLab/validateLabForm";

const LabApppointmentForm = ({
  appointmentDetails = null,
  setDatasFn = null,
  is_follow_up,
  setOpen,
}) => {
  const initialState = {
    patient_id: is_follow_up
      ? appointmentDetails?.patient_id?._id
      : appointmentDetails?._id,
    payment_status: "Select Status",
    payment_method: "Select Method",
    date: "",
    time: "",
  };
  const [err, setError] = useState({ global: "" });
  const [loading, setLoading] = useState(false);
  const [formData, handleInput, clearForm] = useForm(initialState);
  const [tests, setTests] = useState([]);
  useEffect(() => {
    getTests()
      .then((res) => {
        setTests(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);
  const [selectedTests, setSelectedTests] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTests, setFilteredTests] = useState([]);
  useEffect(() => {
    const filteredTests = tests?.filter((test) => {
      const nameMatch = test?.test_name
        ?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
      const tIdMatch = test?.test_id
        ?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
      const departmentMatch = test?.department
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      const categoryMatch = test?.category?.category_name
        ?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
      return nameMatch || tIdMatch || departmentMatch || categoryMatch;
    });
    setFilteredTests(filteredTests);
  }, [tests, searchQuery]);
  const handleSelctedTests = (test, method) => {
    if (method === "add") {
      if (!selectedTests.some((item) => test._id === item._id)) {
        setSelectedTests([...selectedTests, test]);
      }
      return;
    } else {
      setSelectedTests(selectedTests.filter((item) => test._id !== item._id));
    }
  };
   const [total_price, setTotalPrice] = useState(0);
   useEffect(() => {
     const totalPrice = selectedTests.reduce(
       (total, test) => total + test.total_price,
       0
     );
     setTotalPrice(Number(totalPrice.toFixed(2)));
   }, [selectedTests]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validataLabForm(formData,tests,setError)) {
      addLabAppointment(formData, tests, total_price, is_follow_up)
        .then((res) => {
          if (setDatasFn) setDatasFn();
          setOpen(false);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  };
  return (
    <>
      <div className="flex w-full h-fit justify-between gap-5">
        <DateInput
          name="Date"
          formName={"date"}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
        <TimeInput
          name="Time"
          formName={"time"}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
      </div>
      {selectedTests?.length > 0 && (
        <div className="flex w-full gap-1 flex-wrap">
          {selectedTests?.map((item, idx) => (
            <div
              className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
              key={idx}
            >
              <span>{item?.test_name}</span>
              <button
                onClick={() => {
                  handleSelctedTests(item, "remove");
                }}
              >
                <AiOutlineCloseCircle fontSize={14} color="red" />
              </button>
            </div>
          ))}
        </div>
      )}
      <div className="flex w-full">
        <SearchInput
          placeholder="Search Test"
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
      </div>
      {filteredTests?.length > 0 ? (
        <div className="w-full overflow-y-auto min-h-[200px]">
          {filteredTests.map((test, index) => (
            <div
              key={index}
              onClick={() => {
                handleSelctedTests(test, "add");
              }}
              className={`flex cursor-pointer w-full h-fit  p-2 gap-3 items-center mb-2 border rounded-md bg-white
                }`}
            >
              <div className="flex w-[50px] h-[50px] rounded-full overflow-hidden">
                <img
                  className="h-full w-full object-cover"
                  src={test?.test_pic}
                  alt=""
                />
              </div>
              <div className="flex flex-col">
                <span className="flex items-end gap-2">
                  <span className="text-base">{test?.test_name}</span>
                </span>
                <span className="text-xs">department: {test?.department}</span>
                <span className="text-xs">
                  category : {test?.category?.category_name}
                </span>
                <span className="text-xs">price : ₹{test?.total_price}</span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <NoDataFound data="tests" />
      )}
      {total_price !== 0 && (
        <div className="flex w-full h-fit items-center gap-1">
          <span>Total - </span>
          <span className="flex items-center gap-0.5">
            <>
              <FaRupeeSign fontSize={12} />
              {total_price}
            </>
          </span>
        </div>
      )}
      <div className="flex w-full h-fit justify-between gap-5">
        <SelectInput
          name="Payment Method"
          formName={"payment_method"}
          items={["Select Method", "Cash", "Online/UPI"]}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
        <SelectInput
          name="Status"
          formName={"payment_status"}
          items={["Select Status", "Pending", "Paid"]}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
      </div>
      {err.global && (
        <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
          {err.global}
        </div>
      )}
      <div className="flex w-full h-fit justify-between gap-5 flex-auto items-end">
        <button
          className="w-full h-fit border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
          onClick={() => {
            clearForm();
            setOpen(false);
          }}
        >
          Cancel
        </button>
        <button
          className="w-full h-fit border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
          disabled={loading}
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default LabApppointmentForm;
