import "./OtpFiled.css";
import { useState, useRef, useEffect } from 'react';


const OtpInput = ({ length = 4, handleSubmit, loading }) => {
    const [otp, setOtp] = useState(new Array(length).fill(""));
     const inputRefs = useRef([]);

     useEffect(() => {
        if (inputRefs.current[0]) {
            inputRefs.current[0]?.focus();
        }
     }, []);
    
    const handleChange = (index, event) => {
        const value = event.target.value.slice(-1);
        if (isNaN(Number(value))) return;
        const updatedOtp = [...otp];
        updatedOtp[index] = value;
        setOtp(updatedOtp);
        // Moving it to next input
        if (index < length - 1 && value !== "" && inputRefs.current[index + 1]) {
            inputRefs.current[index + 1]?.focus();
        }
        // Submit trigger
        const combainedOtp = updatedOtp.join("");
        if (combainedOtp.length === length) handleSubmit(combainedOtp.toString());
    };

    const handleClick = (index) => {
        inputRefs.current[index]?.setSelectionRange(1, 1);
        if (index > 0 && !otp[index - 1]) {
            inputRefs.current[otp.indexOf("")]?.focus();
        }
    };

    const handleKeyDown = (index, event) => {
        if (index > 0 && event.key === "Backspace" && !otp[index] && inputRefs.current[index - 1]) {
            // Moving it to prev input
            inputRefs.current[index - 1]?.focus();
        }
    };

    return (
      <div className="flex flex-col gap-5 items-center">
        <div>
          {otp.map((item, index) => (
            <input
              key={index}
              type="text"
              value={item}
              ref={(input) => (inputRefs.current[index] = input)}
              className="otp-input"
              onChange={(event) => handleChange(index, event)}
              onClick={() => handleClick(index)}
              onKeyDown={(event) => handleKeyDown(index, event)}
            />
          ))}
        </div>
        <button
          disabled={loading}
          onClick={handleSubmit}
          className="w-fit h-fit py-1 justify-center items-center px-5 text-white bg-primary rounded-md"
        >
          {loading ? "Loading..." : "Submit"}
        </button>
      </div>
    );
}

export default OtpInput;
