import React, { useState } from 'react'
import { TiArrowLeft } from 'react-icons/ti';
import { OtpInput } from '../common';
import { verifyRegisterWithOtp } from '../../utils/registerUtils/registerUtil';

const RegisterOtpField = ({ response, setOtpSended, setOtpVerified }) => {
  const [err, setError] = useState({ global: "" });
  const [loading, setLoading] = useState(false);
  const handleOtpVerification = (otpVerify) => {
    if (!/^.{4}$/.test(otpVerify)) {
      setError({ global: "Invalid Otp" });
      return;
    }
    setError({global:""})
    setLoading(true);
    verifyRegisterWithOtp(response?.email, otpVerify, setError)
      .then((res) => {
        setOtpVerified(true);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };
  return (
    <>
      <div className="flex w-full h-full justify-center items-center flex-col gap-8 py-5">
        <label htmlFor="otpFields" className="text-base text-shadow-black">
          Verification code<span className="text-red-600 ml-1">*</span>
        </label>
        <div className="flex justify-center items-center gap-1.5">
          <OtpInput handleSubmit={handleOtpVerification} loading={loading} />
        </div>
        <span
          onClick={() => {
            setError({ global: "" });
            setOtpSended(false);
          }}
          className="w-fit flex gap-1 items-center justify-center text-secondary text-sm cursor-pointer hover:text-primary"
        >
          <TiArrowLeft /> back
        </span>
        {err.global && (
          <div className="flex w-full h-fit text-red-600 justify-center items-center font-semibold text-sm">
            {err.global}
          </div>
        )}
      </div>
    </>
  );
};

export default RegisterOtpField