import React from 'react'

const BlackBarSvg = () => {
  return (
    <div className='hidden lg:flex'>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="3"
        height="67"
        viewBox="0 0 3 67"
        fill="none"
      >
        <ellipse
          cx="1.5"
          cy="33.5"
          rx="1.5"
          ry="33.5"
          fill="url(#paint0_radial_81_19)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_81_19"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1.5 33.5) rotate(90) scale(33.5 1.5)"
          >
            <stop offset="0.255208" />
            <stop offset="1" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
}

export default BlackBarSvg